import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  fetchCategories,
  fetchOrganisations,
  fetchUser,
  fetchUserBackground,
  setIsMobileApp,
} from '../actions/user'
import Head from 'next/head'
import { darkTheme, lightTheme } from '../shared-components/theme'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import DynamicReduxModal from './modals/DynamicReduxModal'
import ConnectedHeader from './common/ConnectedHeader'
import mixpanel from '../shared-components/utils/mixpanel'
import dynamic from 'next/dynamic'
import { SplitFactory } from '@splitsoftware/splitio-react'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { useRouter } from 'next/router'
import { growthbook } from '../config/growthbook'
import { checkForFirstName, useUser } from '../utils/userFunctions'
import { openSignInModal } from '../actions/modals'
import Snackbar from '../shared-components/snackbar/Snackbar'
import { getCurrentEvent, getIsEventsAdmin } from 'selectors/event'
import AdminOverlay from './Customisation/AdminOverlay'
import ScraperProgressView from './Scraper/ScraperProgressView'
import { createCustomTheme } from '../shared-components/customTheme'

// const Snackbar = dynamic(() => import('shared-components/snackbar/Snackbar'))
const DownloadDialog = dynamic(() => import('components/common/DownloadDialog'))
const FollowDialog = dynamic(
  () => import('components/organisations/FollowDialog'),
)
const FollowUserDialog = dynamic(
  () => import('components/user/FollowUserDialog'),
)
const SignInModal = dynamic(() => import('components/modals/SignInModal'))
// const GuestInfoSheet = dynamic(() => import('components/modals/GuestInfoSheet'))
// const GuestAnswersSheet = dynamic(() => import('components/modals/GuestAnswersSheet'))
const UserInfoSheet = dynamic(() => import('components/modals/UserInfoSheet'))
const CreatePageModal = dynamic(
  () => import('components/modals/CreatePageModal'),
)
const TicketStatsSheet = dynamic(() => import('./event/TicketStatsSheet'))
const VerifyEmailModal = dynamic(() => import('./modals/VerifyEmailModal'))
const PostRsvpModal = dynamic(() => import('./modals/PostRsvpModal'))
const TicketsModal = dynamic(() => import('./modals/TicketsModal'))

if (typeof window !== 'undefined') {
  if (window.localStorage && !window.localStorage.getItem('has_visited')) {
    mixpanel.track('First Visit')
    window.localStorage.setItem('has_visited', true)
  }
}

let key = 'server'
if (typeof window !== 'undefined') key = mixpanel.get_distinct_id()

const splitSdkConfig = {
  core: {
    authorizationKey: process.env.NEXT_PUBLIC_SPLIT_KEY,
    key: key,
  },
  impressionListener: {
    logImpression: (data) => {
      if (!data || !data.impression) return
      data.impression.treatment !== 'control' &&
        console.log(
          'Experiment name:',
          data.impression.feature,
          'Variant name:',
          data.impression.treatment,
        )
      data.impression.treatment !== 'control' &&
        mixpanel.track('$experiment_started', {
          'Experiment name': data.impression.feature,
          'Variant name': data.impression.treatment,
        })
    },
  },
}

const AppWrapper = (props) => {
  const { children, title, appProps } = props
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { isLoggedIn, ready } = useUser()
  const router = useRouter()
  const themeQuery = router.query.theme
  const signInQuery = router.query.signIn
  const event = useSelector(getCurrentEvent)
  const page = useSelector((state: any) => state.organisation.organisation)
  const isEventsAdmin = useSelector(getIsEventsAdmin)

  // Create custom theme if on event page and event has a theme
  const customTheme = useMemo(() => {
    // Check if we're on an event page by checking the route
    const isEventPage =
      router.pathname.startsWith('/events/') ||
      router.pathname.startsWith('/e/')
    const isPagePage = router.pathname.startsWith('/[username]')

    if (themeQuery === 'dark') return darkTheme
    if (themeQuery === 'light') return lightTheme

    // Only apply custom theme if on event page and event has theme
    if (isEventPage && event?.theme) {
      return createCustomTheme(event.theme)
    }

    if (isPagePage && page?.theme) {
      return createCustomTheme(page.theme)
    }

    return lightTheme
  }, [event?.theme, page?.theme, router.pathname, themeQuery])

  const user = useSelector((state: any) => state.user.user)

  const organisationsState = useSelector(
    (state: any) => state.user.organisations,
  )
  const organisations = organisationsState.organisations

  const categoriesState = useSelector((state: any) => state.user.categories)
  const categories = categoriesState.categories
  const signInModalOpen = useSelector((state: any) => state.modals.open.signIn)

  useEffect(() => {
    if (user?.id) {
      growthbook.setAttributes({
        mixpanelId: mixpanel.get_distinct_id(),
        id: user.id,
      })
    }
  }, [user])

  useEffect(() => {
    if (ready && signInQuery === 'true' && !isLoggedIn) {
      dispatch(openSignInModal())
    }
  }, [signInQuery, isLoggedIn, ready])

  useEffect(() => {
    // Check for mobile parameter in URL
    const isMobileParam = router.query.isMobileApp === 'true'
    if (isMobileParam) {
      dispatch(setIsMobileApp(true))
    }
  }, [router.query, dispatch])

  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures()
    growthbook.setAttributes({
      mixpanelId: mixpanel.get_distinct_id(),
    })
  }, [])

  useEffect(() => {
    dispatch(fetchUser())
  }, [dispatch])

  useEffect(() => {
    if (user?.id) {
      if (typeof window !== 'undefined' && !signInModalOpen) {
        checkForFirstName(user, window.location.pathname, false, false)
      }
    }
  }, [user])

  useEffect(() => {
    user?.id &&
      !organisationsState.pending &&
      !organisationsState.isFulfilled &&
      !organisationsState.isRejected &&
      dispatch(fetchOrganisations())
  }, [dispatch, organisations, user])

  useEffect(() => {
    user?.id &&
      !categoriesState.pending &&
      !categoriesState.isFulfilled &&
      !categoriesState.isRejected &&
      dispatch(fetchCategories())
  }, [dispatch, categories, user])

  useEffect(() => {
    dispatch(fetchUserBackground())
  }, [router.asPath])

  // Modified useEffect to check current route
  useEffect(() => {
    const isPagePage =
      router.pathname.startsWith('/[username]') ||
      router.pathname.startsWith('/pages/')

    // Reset favicon if we're not on a page route, or if we are but there's no avatar
    if (!isPagePage || !page?.avatarUrls?.lg) {
      const resetFavicon = () => {
        // First remove existing favicons
        const existingFavicons = document.querySelectorAll("link[rel*='icon']")
        existingFavicons.forEach((favicon) => {
          favicon.parentNode?.removeChild(favicon)
        })

        // Force browser to clear favicon cache
        const clearFavicon = document.createElement('link')
        clearFavicon.rel = 'shortcut icon'
        clearFavicon.href = 'data:image/x-icon;base64,AA'
        document.head.appendChild(clearFavicon)

        // Small delay before setting new favicon
        setTimeout(() => {
          clearFavicon.remove()

          // Then set new ones with cache-busting
          const timestamp = Date.now()
          const favicon = document.createElement('link')
          favicon.rel = 'shortcut icon'
          favicon.href = `/favicon.ico?v=${timestamp}`
          document.head.appendChild(favicon)

          const favicon32 = document.createElement('link')
          favicon32.rel = 'icon'
          favicon32.type = 'image/png'
          favicon32.sizes = '32x32'
          favicon32.href = `/favicon-32x32.png?v=${timestamp}`
          document.head.appendChild(favicon32)

          const favicon16 = document.createElement('link')
          favicon16.rel = 'icon'
          favicon16.type = 'image/png'
          favicon16.sizes = '16x16'
          favicon16.href = `/favicon-16x16.png?v=${timestamp}`
          document.head.appendChild(favicon16)
        }, 50)
      }
      resetFavicon()
    }
  }, [page?.avatarUrls?.lg, router.pathname])

  return (
    <>
      <Head>
        <link
          href='https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap'
          rel='stylesheet'
          crossOrigin='anonymous'
        />
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/icon?family=Material+Icons'
          crossOrigin='anonymous'
        />

        {title != null && <title key={'title'}>{title}</title>}
        <meta
          name='description'
          content='Where Hype Starts'
          key='description'
        />
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
        <meta key='ogTitle' property='og:title' content='BASH' />
        <meta
          key='ogDescription'
          property='og:description'
          content='Where Hype Starts'
        />
        <meta
          key='ogImage'
          property='og:image'
          itemProp='image'
          content='/android-chrome-192x192.png'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        {/* Only render these links if we're on a page with an avatar */}
        {page?.avatarUrls?.lg ? (
          <>
            <link
              rel='apple-touch-icon'
              sizes='180x180'
              href={page.avatarUrls.lg}
            />
            <link
              rel='icon'
              type='image/png'
              sizes='32x32'
              href={page.avatarUrls.lg}
            />
            <link
              rel='icon'
              type='image/png'
              sizes='16x16'
              href={page.avatarUrls.lg}
            />
            <link rel='shortcut icon' href={page.avatarUrls.lg} />
          </>
        ) : (
          <>
            <link
              rel='apple-touch-icon'
              sizes='180x180'
              href={`/apple-touch-icon.png?v=${Date.now()}`}
            />
            <link
              rel='icon'
              type='image/png'
              sizes='32x32'
              href={`/favicon-32x32.png?v=${Date.now()}`}
            />
            <link
              rel='icon'
              type='image/png'
              sizes='16x16'
              href={`/favicon-16x16.png?v=${Date.now()}`}
            />
            <link rel='shortcut icon' href={`/favicon.ico?v=${Date.now()}`} />
          </>
        )}
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#fafafa' />
      </Head>
      <ThemeProvider theme={customTheme}>
        <AdminOverlay>
          <GrowthBookProvider growthbook={growthbook}>
            <SplitFactory config={splitSdkConfig}>
              <CssBaseline enableColorScheme />
              <Snackbar />
              <DynamicReduxModal
                reduxName='follow'
                DynamicComponent={FollowDialog}
              />
              <DynamicReduxModal
                reduxName='followUser'
                DynamicComponent={FollowUserDialog}
              />
              <DynamicReduxModal
                reduxName='userInfo'
                DynamicComponent={UserInfoSheet}
              />
              <DynamicReduxModal
                reduxName='download'
                DynamicComponent={DownloadDialog}
              />
              <DynamicReduxModal
                reduxName='createPage'
                DynamicComponent={CreatePageModal}
              />
              <DynamicReduxModal
                reduxName='ticketStats'
                DynamicComponent={TicketStatsSheet}
              />
              <DynamicReduxModal
                reduxName='verifyEmail'
                DynamicComponent={VerifyEmailModal}
              />
              <DynamicReduxModal
                reduxName='postRsvp'
                DynamicComponent={PostRsvpModal}
              />
              <DynamicReduxModal
                reduxName='ticketView'
                DynamicComponent={TicketsModal}
              />
              <ConnectedHeader />
              <SignInModal />
              {children}
              {isEventsAdmin && router.route.includes('admin/pages') && (
                <ScraperProgressView />
              )}
            </SplitFactory>
          </GrowthBookProvider>
        </AdminOverlay>
      </ThemeProvider>
    </>
  )
}

export default AppWrapper
// export default wrapper.withRedux(AppWrapper)